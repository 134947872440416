// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
// eslint-disable-next-line simple-import-sort/imports
import { config, library } from '@fortawesome/fontawesome-svg-core';
import { faAngellist, faApple, faFacebookF, faGooglePlay, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {
    faAlarmClock as faAlarmClockDuo,
    faAnalytics as faAnalyticsDuo,
    faAngleRight as faAngleRightDuo,
    faAngleUp as faAngleUpDuo,
    faBallotCheck as faBallotCheckDuo,
    faBars as faBarsDuo,
    faBinoculars as faBinocularsDuo,
    faChartNetwork as faChartNetworkDuo,
    faClipboardListCheck as faClipboardListCheckDuo,
    faComments as faCommentsDuo,
    faDatabase as faDatabaseDuo,
    faEnvelope as faEnvelopeDuo,
    faFileInvoice as faFileInvoiceDuo,
    faBook as faBookDuo,
    faFillDrip as faFillDripDuo,
    faGraduationCap as faGraduationCapDuo,
    faHandsHelping as faHandsHelpingDuo,
    faHandsUsd as faHandsUsdDuo,
    faHeadSideBrain as faHeadSideBrainDou,
    faVirus as faVirusDuo,
    faDna as faDnaDuo,
    faBrain as faBrainDuo,
    faInfoCircle as faInfoCircleDuo,
    faMask as faMaskDuo,
    faMicroscope as faMicroscopeDuo,
    faNewspaper as faNewspaperDuo,
    faPencilRuler as faPencilRulerDuo,
    faSearch as faSearchDuo,
    faSensorOn as faSensorOnDuo,
    faSlidersV as faSlidersVDuo,
    faUniversity as faUniversityDuo,
    faUserAstronaut as faUserAstronautDuo,
    faUsers as faUsersDuo,
    faAppleAlt as faAppleAltDuo,
    faUserNurse as faUserNurseDuo,
    faWebcam as faWebcamDuo,
} from '@fortawesome/pro-duotone-svg-icons';
import {
    faAngleDown as faAngleDownLight,
    faAngleLeft as faAngleLeftLight,
    faAngleRight as faAngleRightLight,
    faAngleUp as faAngleUpLight,
    faEllipsisHAlt as faEllipsisHAltLight,
    faLongArrowRight as faLongArrowRightLight,
    faSearch as faSearchLight,
} from '@fortawesome/pro-light-svg-icons';
import { faLongArrowRight as faLongArrowRightSolid, faTimes as faTimesSolid } from '@fortawesome/pro-solid-svg-icons';

import '@fortawesome/fontawesome-svg-core/styles.css';

// Prevent fontawesome from adding its CSS since we did it manually above:
config.autoAddCss = false;

const icons = [
    // Light
    faLongArrowRightLight,
    faAngleUpLight,
    faAngleDownLight,
    faAngleRightLight,
    faAngleLeftLight,
    faEllipsisHAltLight,
    faSearchLight,
    // Brands
    faLinkedinIn,
    faFacebookF,
    faTwitter,
    faAngellist,
    faApple,
    faGooglePlay,
    // Duotone
    faFileInvoiceDuo,
    faSensorOnDuo,
    faUsersDuo,
    faAppleAltDuo,
    faUserNurseDuo,
    faWebcamDuo,
    faSearchDuo,
    faAnalyticsDuo,
    faSlidersVDuo,
    faPencilRulerDuo,
    faHandsHelpingDuo,
    faHandsUsdDuo,
    faAngleRightDuo,
    faAngleUpDuo,
    faMicroscopeDuo,
    faUniversityDuo,
    faUserAstronautDuo,
    faChartNetworkDuo,
    faAlarmClockDuo,
    faUsersDuo,
    faDatabaseDuo,
    faBinocularsDuo,
    faHeadSideBrainDou,
    faDnaDuo,
    faVirusDuo,
    faBrainDuo,
    faClipboardListCheckDuo,
    faFillDripDuo,
    faBookDuo,
    faInfoCircleDuo,
    faNewspaperDuo,
    faGraduationCapDuo,
    faCommentsDuo,
    faMaskDuo,
    faEnvelopeDuo,
    faBallotCheckDuo,
    faBarsDuo,
    // Solids
    faLongArrowRightSolid,
    faTimesSolid,
];

// eslint-disable-next-line import/prefer-default-export
export function initialize() {
    library.add(...icons);
}
