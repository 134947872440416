import { createMuiTheme } from '@material-ui/core/styles';

import landingPageTheme from './landing-page.theme';

export default createMuiTheme(landingPageTheme, {
    props: {
        MuiButton: {
            color: 'primary',
            variant: 'contained',
        },
    },
    overrides: {
        MuiButton: {
            root: {
                ...landingPageTheme.typography.button,
                whiteSpace: 'nowrap',
            },
            containedPrimary: {
                padding: landingPageTheme.spacing(1.75),
                minWidth: 168,
                color: 'white',
                backgroundColor: landingPageTheme.palette.logo.blue.main,
                '&:hover, &:focus': {
                    backgroundColor: landingPageTheme.palette.logo.blue.dark,
                    color: 'white',
                    '@media (hover: none)': {
                        backgroundColor: landingPageTheme.palette.logo.blue.dark,
                        color: 'white',
                    },
                },
            },
            text: {
                minWidth: 168,
            },
            textPrimary: {
                color: landingPageTheme.palette.logo.blue.main,
                '&:hover, &:focus': {
                    color: landingPageTheme.palette.logo.blue.dark,
                    '@media (hover: none)': {
                        color: landingPageTheme.palette.logo.blue.dark,
                    },
                },
            },
        },
    },
});
