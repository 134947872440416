import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        type: 'light',
        background: {
            default: 'white',
        },
        primary: {
            light: '#87acff',
            main: '#4e7de8',
            dark: '#0052b5',
            contrastText: 'white',
        },
        secondary: {
            main: '#fff',
            contrastText: '#0052b5',
        },
        text: {
            primary: '#081f4d',
            secondary: '#333',
        },
        error: {
            main: '#ff375f',
        },
        success: {
            main: '#30d158',
        },
        info: {
            main: '#0b83ff',
        },
        logo: {
            blue: {
                light: '#87acff',
                main: '#4e7de8',
                dark: '#0052b5',
            },
            green: {
                light: '#b5fff8',
                main: '#82d7c5',
                dark: '#50a595',
            },
            purple: {
                light: '#b0bdff',
                main: '#7c8df6',
                dark: '#474EB8',
            },
            yellow: {
                light: '#ffe888',
                main: '#ebb659',
                dark: '#b6862a',
            },
        },
    },
    typography: {
        fontFamily: [
            'Open Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            fontSize: '2rem',
            fontWeight: 700,
            lineHeight: 1.379,
            letterSpacing: -0.5,
        },
        h2: {
            fontSize: '1.8rem',
            fontWeight: 700,
            lineHeight: 1.379,
            letterSpacing: -0.5,
        },
        h3: {
            fontSize: '1.5rem',
            fontWeight: 700,
            lineHeight: 1.379,
            letterSpacing: -0.5,
        },
        h4: {
            fontSize: '1.35rem',
            fontWeight: 700,
            lineHeight: 1.6,
            letterSpacing: 0.25,
        },
        h5: {
            fontSize: '1.2rem',
            fontWeight: 700,
            lineHeight: 1.4,
        },
        h6: {
            fontSize: '1em',
            fontWeight: 600,
            lineHeight: 1.6,
        },
        subtitle1: {
            fontSize: '1.1rem',
            fontWeight: 600,
            lineHeight: 1.5,
        },
        subtitle2: {
            fontSize: '1rem',
            fontWeight: 600,
            lineHeight: 1.6,
            letterSpacing: '0.02em',
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.75,
            letterSpacing: '0.02em',
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: 1.6,
            letterSpacing: '0.02em',
        },
        button: {
            fontSize: '0.8rem',
            fontWeight: 600,
            lineHeight: 1.3575,
            letterSpacing: '0.0536em',
        },
        caption: {
            letterSpacing: '.4px',
        },
    },
});

export default theme;
