// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-401-js": () => import("./../../../src/pages/401.js" /* webpackChunkName: "component---src-pages-401-js" */),
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-a-demo-js": () => import("./../../../src/pages/book-a-demo.js" /* webpackChunkName: "component---src-pages-book-a-demo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-usecases-js": () => import("./../../../src/pages/usecases.js" /* webpackChunkName: "component---src-pages-usecases-js" */),
  "component---src-site-pages-feature-page-index-js": () => import("./../../../src/site-pages/feature-page/index.js" /* webpackChunkName: "component---src-site-pages-feature-page-index-js" */),
  "component---src-site-pages-legal-index-page-index-js": () => import("./../../../src/site-pages/legal-index-page/index.js" /* webpackChunkName: "component---src-site-pages-legal-index-page-index-js" */),
  "component---src-site-pages-page-activity-page-index-js": () => import("./../../../src/site-pages/page-activity-page/index.js" /* webpackChunkName: "component---src-site-pages-page-activity-page-index-js" */),
  "component---src-site-pages-study-page-index-js": () => import("./../../../src/site-pages/study-page/index.js" /* webpackChunkName: "component---src-site-pages-study-page-index-js" */),
  "component---src-site-pages-therapeutic-area-page-index-js": () => import("./../../../src/site-pages/therapeutic-area-page/index.js" /* webpackChunkName: "component---src-site-pages-therapeutic-area-page-index-js" */),
  "component---src-site-pages-usecase-page-index-js": () => import("./../../../src/site-pages/usecase-page/index.js" /* webpackChunkName: "component---src-site-pages-usecase-page-index-js" */)
}

