import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    '@global': {
        body: {
            ...theme.typography.body1,
            overflowX: 'hidden',
        },
        a: {
            textDecoration: 'none',
            color: theme.palette.logo.blue.main,
        },
        article: {
            '& h1': {
                ...theme.typography.h1,
                marginBottom: [theme.spacing(2), '!important'],
            },
            '& h2': {
                ...theme.typography.h2,
                marginBottom: [theme.spacing(2), '!important'],
                marginTop: [theme.spacing(10), '!important'],
                '&:first-child': {
                    marginTop: 0,
                },
            },
            '& h3': {
                ...theme.typography.h3,
                marginBottom: [theme.spacing(1), '!important'],
                marginTop: [theme.spacing(5), '!important'],
            },
            '& h4': {
                ...theme.typography.h4,
                marginBottom: [theme.spacing(1), '!important'],
            },
            '& h5': {
                ...theme.typography.h5,
                marginBottom: [theme.spacing(1), '!important'],
            },
            '& h6': {
                ...theme.typography.h6,
            },
            '& p': {
                ...theme.typography.body1,
                marginBottom: [theme.spacing(1), '!important'],
            },
            '& ul, & ol': {
                marginLeft: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
            '& ol': {
                counterReset: 'li',
            },
            '& li': {
                ...theme.typography.body1,
                listStyle: 'none',
                position: 'relative',
                padding: theme.spacing(0, 0, 0, 3),
                '& > p': {
                    marginBottom: 0,
                },
            },
            '& ul > li::before': {
                content: '""',
                background: theme.palette.logo.blue.main,
                borderRadius: '50%',
                position: 'absolute',
                width: theme.spacing(2),
                height: theme.spacing(2),
                marginRight: theme.spacing(2),
                top: 6,
                left: -5,
            },
            '& ol > li': {
                counterIncrement: 'li',
            },
            '& ol > li::before': {
                ...theme.typography.h5,
                content: 'counter(li)',
                color: theme.palette.logo.blue.main,
                position: 'absolute',
                marginRight: theme.spacing(2),
                top: 0,
                left: -9,
            },
        },
    },
}));
