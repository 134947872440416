import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const PageElementWrapper = (props) => {
    const { children } = props;
    useStyles();
    return <>{children}</>;
};

PageElementWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PageElementWrapper;
