import { create } from 'jss';
import jssPluginTemplate from 'jss-plugin-template';
import { jssPreset } from '@material-ui/styles';

const stylesProviderProps = {
    jss: create({
        plugins: [...jssPreset().plugins, jssPluginTemplate()],
    }),
};

export default stylesProviderProps;
